@import '../../../lib/styles/mixins';
@import '../../../lib/styles/variables';

.wrapper {
   .header {
      border: solid 2px $border-color-main;
      box-shadow: $box-shadow-main;
      border-radius: 5px;
      @include flex-center;
      gap: 20px;

      .btn {
         margin: 2px 0;
         width: 120px;
         height: 40px;
         text-decoration: none;
         background-color: #0693e3;
         color: #fff;
         border: 2px solid;
         border-color: #0693e3;
         border-radius: 5px;
         cursor: pointer;
         transition: background-color 0.3s;

         font: {
            family: 'Roboto';
            weight: 400;
            size: 0.8rem;
         }
      }

      .btn:hover {
         background-color: #fff;
         color: #0693e3;
      }

      font: {
         family: 'Roboto';
      }

      .dataDelivery {
         @include flex;
         gap: 5px;
      }

      .addComponentForm {
         position: fixed;
         top: 10%;
         border: solid 2px $border-color-main;
         box-shadow: $box-shadow-main;
         border-radius: 5px;
         width: 300px;
         height: 300px;
         background-color: #fff;
         padding: 10px;
         @include flex-center;
         text-align: center;

         h3 {
            margin: 0;
            margin-bottom: 15px
         }

         .FormBox {
            @include flex-column;
            gap: 10px;

            div {
               @include flex-space-btw
            }
         }
      }

      .selectStatus {
         position: fixed;
         top: 10%;
         border: solid 2px $border-color-main;
         box-shadow: $box-shadow-main;
         border-radius: 5px;
         background-color: #fff;
         padding: 10px;

         text-align: center;

         h3 {
            margin: 0;
            margin-bottom: 15px
         }

         .setStatusForm {
            @include flex-column;
            gap: 10px;

            .setStatusSelects {
               width: 100%;
               @include flex-space-btw;

               .setStatus {
                  @include flex-space-btw
               }
            }
         }
      }


      .buttons {
         @include flex-column;
      }
   }

   .table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      table-layout: fixed;

      .tHead {
         background-color: #0693e3;
         color: white;
         font-weight: bold;

         th {
            font-size: 14px;
            border: 2px solid #ddd;
            padding: 2px;
         }
      }

      .tBody {
         .bodyTr {
            font-size: 10px;
            transition: background-color 0.3s;

            &:nth-child(even) {
               background-color: #f2f2f2;
            }

            td {
               border: 1px solid #ddd;
               text-align: center;
            }
         }

         .bodyTr:hover {
            background-color: #2980b9;
            color: white;
         }
      }
   }
}