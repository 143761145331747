@import '../../lib/styles/mixins';
@import '../../lib/styles/variables';

.wrapper {
    width: 99%;
    margin: auto;

    .btnBox {
        .btn {
            background-color: #0693e3;
            color: #fff;
            border: 2px solid;
            border-color: #0693e3;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
            padding: 5px;
            margin-right: 2px;

            font: {
                family: 'Roboto';
                size: 16px;
            }
        }

        .btn:hover {
            background-color: #fff;
            color: #0693e3;
        }

        .nBtn {
            background-color: #ddd;
            color: #fff;
            border: 2px solid;
            border-color: #ddd;
            border-radius: 5px;
            transition: background-color 0.3s;
            padding: 5px;
            margin-right: 2px;

            font: {
                family: 'Roboto';
                size: 16px;
            }
        }
    }

    .componentTable {
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;

        .selectedRow {
            background-color: #0693e3;
        }

        th,
        td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
        }
    }
}