@import 'lib/styles/mixins';
@import 'lib/styles/variables';

.button {
    margin: 2px 2px;
    padding: 5px;
    text-decoration: none;
    background-color: #0693e3;
    color: #fff;
    border: 2px solid;
    border-color: #0693e3;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

}

.button:hover {
    background-color: #fff;
    color: #0693e3;
}