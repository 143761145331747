@import '../../lib/styles/mixins';
@import '../../lib/styles/variables';

.wrapper {
    .formLoginWrapper {
        background-color: #fff;
        border: 2px solid #0693e3;
        border-radius: 5px;
        @include flex;
        @include flex-space-btw;
        margin: auto;
        box-shadow: $box-shadow-main;

        font: {
            family: 'Roboto';
            weight: 400;
        }

        .logoBox {
            margin: auto;
            @include flex;
            align-items: center;
            justify-content: center;
            height: 90%;
            width: 50%;
            border-right: solid 2px $border-color-main;

            .logo {
                margin: auto;
                width: 70%;
                height: 50%;
            }
        }

        .loginFormBox {
            flex: 1;
            @include flex-column;
            @include flex-center;
            text-align: left;

            label {
                display: block;
                text-align: left;
                max-width: 80%;
                margin-bottom: 3px;
            }

            input {
                box-sizing: border-box;
                width: 80%;
                padding: 2px;
                margin-bottom: 5px;
            }

            .btn {
                width: 30%;
                color: #fff;
                cursor: pointer;
                background-color: $border-color-main;
                border-color: $border-color-main;
                transition: background-color 0.3s;

                font: {
                    weight: 700;
                }
            }

            .btn:hover {
                background-color: #fff;
                color: $color-btn-main;
            }

            .error {
                color: red;
            }
        }
    }
}

@media (max-width: 320px) {
    .wrapper {
        .formLoginWrapper {
            height: 160px;

            .logoBox {
                .logo {
                    width: 70px;
                    height: 70px;
                }
            }

            .loginFormBox {
                label {
                    max-width: 80%;
                    margin-bottom: 3px;
                    font-size: 10px;
                }

                input {
                    width: 80%;
                    padding: 2px;
                    margin-bottom: 5px;
                    font-size: 8px;
                }

                .btn {
                    width: 60px;
                    font-size: 8px;
                }

                .error {
                    font-size: 6px;
                }
            }
        }
    }
}

@media (min-width: 321px) and (max-width: 375px) {
    .wrapper {
        .formLoginWrapper {
            height: 250px;
            .logoBox {
                .logo {
                    width: 125px;
                    height: 125px;
                }
            }

            .loginFormBox {
                label {
                    font-size: 10px;
                }

                input {
                    font-size: 8px;
                }

                .btn {
                    width: 55px;
                    font-size: 8px;
                }

                .error {
                    font-size: 8px;
                }
            }
        }
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .wrapper {
        .formLoginWrapper {
            height: 250px;
            width: 350px;
            .logoBox {
                .logo {
                    width: 140px;
                    height: 140px;
                }
            }

            .loginFormBox {
                label {
                    font-size: 12px;
                }

                input {
                    font-size: 10px;
                }

                .btn {
                    width: 60px;
                    font-size: 10px;
                }

                .error {
                    font-size: 8px;
                }
            }
        }
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .wrapper {
        .formLoginWrapper {
            margin-top: 6%;
            height: 250px;
            width: 350px;
            .logoBox {
                .logo {
                    width: 140px;
                    height: 140px;
                }
            }

            .loginFormBox {
                label {
                    font-size: 16px;
                }

                input {
                    font-size: 14px;
                }

                .btn {
                    padding: 5px;
                    width: 70px;
                    font-size: 12px;
                }

                .error {
                    font-size: 12px;
                }
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .wrapper {
        .formLoginWrapper {
            margin-top: 6%;
            height: 350px;
            width: 450px;
            .logoBox {
                .logo {
                    width: 160px;
                    height: 160px;
                }
            }

            .loginFormBox {
                label {
                    font-size: 18px;
                }

                input {
                    font-size: 16px;
                }

                .btn {
                    padding: 5px;
                    width: 70px;
                    font-size: 14px;
                }

                .error {
                    font-size: 12px;
                }
            }
        }
    }
}

@media (min-width: 1025px) {
    .wrapper {
        .formLoginWrapper {
            margin-top: 6%;
            height: 400px;
            width: 500px;
            .logoBox {
                .logo {
                    width: 180px;
                    height: 180px;
                }
            }

            .loginFormBox {
                label {
                    font-size: 18px;
                }

                input {
                    font-size: 16px;
                }

                .btn {
                    padding: 5px;
                    width: 70px;
                    font-size: 14px;
                }

                .error {
                    font-size: 12px;
                }
            }
        }
    }
}