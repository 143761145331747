@import '../../../lib/styles/mixins';
@import '../../../lib/styles/variables';

.wrapper {

   .table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      table-layout: fixed;




      .tHead {
         background-color: #0693e3;
         color: white;
         font-weight: bold;

         th {
            font-size: 14px;
            border: 2px solid #ddd;
            padding: 2px;

            .filterIcon {
               cursor: pointer;
               color: #ddd;
            }

            .filterWrapper {
               input {
                  margin-top: 2px;
                  width: 80%;
                  border: 1px solid #ddd;
                  border-radius: 4px;
               }
            }
         }
      }

      .tBody {
         .bodyTr {
            font-size: 12px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:nth-child(even) {
               background-color: #f2f2f2;
            }

            td {
               border: 1px solid #ddd;
               text-align: center;
            }
         }

         .bodyTr:hover {
            background-color: #2980b9;
            color: white;
         }

         .bodyTr:hover {
            background-color: #3498db;
         }
      }



      .realised {
         background-color: rgba(6, 227, 54, 0.5);
      }

      .realised:hover {
         background-color: rgba(6, 227, 54, 0.8);
      }

      .toRealised {
         background-color: rgba(255, 230, 0, 0.5);
      }

      .toRealised:hover {
         background-color: rgba(255, 230, 0, 0.8);
      }

      input {
         width: 50%;
      }

   }
}