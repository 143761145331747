@import '../../lib/styles/mixins';
@import '../../lib/styles/variables';

.wrapper {
   .tableWrapper {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;

      th,
      td {
         border: 1px solid #ddd;
         text-align: left;
         cursor: pointer;
      }

      .ok {
         background-color: rgba(6, 227, 54, 0.5);
      }

      .ok:hover {
         background-color: rgba(6, 227, 54, 0.8);
      }

      .nok {
         background-color: rgba(227, 54, 6, 0.5);
      }

      .nok:hover {
         background-color: rgba(227, 54, 6, 0.8);
      }

      th {
         background-color: #f2f2f2;
         width: 20%
      }

      tr:hover {
         background-color: #f5f5f5;
      }

      input {
         width: 50%
      }

   }
}

@media (max-width: 319px) {
   tr {
      font-size: 8px;

      input {
         font-size: 4px;
      }

      .date {
         display: none;
      }

      .clientName {
         display: none;
      }
   }
}

@media (min-width: 320px) and (max-width: 479px) {
   tr {
      font-size: 8px;

      input {
         font-size: 4px;
      }

      .date {
         display: none;
      }
   }
}

@media (min-width: 480px) and (max-width: 766px) {
   tr {
      font-size: 9px;

      input {
         font-size: 7px;
      }
   }
}

@media (min-width: 767px) and (max-width: 1023px) {
   tr {
      font-size: 15px;

      input {
         font-size: 10px;
      }
   }
}

@media (min-width: 1024px) and (max-width: 1439px) {
   tr {
      font-size: 20px;

      input {
         font-size: 15px;
      }
   }
}

@media (min-width: 1440px) {
   tr {
      font-size: 25px;

      input {
         font-size: 20px;
      }
   }
}