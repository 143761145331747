@import 'lib/styles/mixins';
@import 'lib/styles/variables';

.selectComponentType {
    border: solid 2px $border-color-main;
    box-shadow: $box-shadow-main;
    border-radius: 8px;
    background-color: #f8f9fa;
    padding: 20px;
    display: flex;
    gap: 20px;
    color: #333;
    font-size: 0.9em;
    margin-bottom: 5px;

    label {
        font-size: 1rem;
        font-weight: 600;
        color: #333;
        margin-bottom: 5px;
    }

    select {
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        color: #333;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;

        &:focus {
            outline: none;
            border-color: #007bff;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }

        &:hover {
            border-color: #999;
        }

        &:disabled {
            background-color: #f5f5f5;
            border-color: #ddd;
            color: #aaa;
            cursor: not-allowed;
        }
    }

    option {
        padding: 0.5rem;
        font-size: 1rem;
    }
}