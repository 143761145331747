//globalne ustawienia

$font-family: 'Roboto';
$font-weight: 400;

// kolor ramy
$border-color-main: #0693e3;

// shadow box
$box-shadow-main: 0 4px 16px rgba(0, 0, 0, 0.25);

//kolor textu
$color-text-logo1: #014972;
$color-text-logo2: #65c4fc;

$color-text-main: #0693e3;

//kolor textu
$color-btn-main: #0693e3;

// shadow box
$background-clor-main: #0693e3;