@import '../../lib/styles/mixins';
@import '../../lib/styles/variables';

.wrapper {
    width: 99%;
    margin: auto;
    @include flex-column;
    gap: 10px;

    font: {
        family: 'Roboto';
    }

    .componentDataWrapper {
        border: solid 2px $border-color-main;
        box-shadow: $box-shadow-main;
        border-radius: 8px;
        background-color: #f8f9fa;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        color: #333;
        font-size: 0.9em;

        .column1,
        .column2,
        .column3,
        .column4 {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;

            div {
                padding: 10px;
                background-color: #fff;
                border: 1px solid #dee2e6;
                border-radius: 5px;
            }
        }
    }

    .actionsWrapper {
        border: solid 2px $border-color-main;
        box-shadow: $box-shadow-main;
        background-color: #f8f9fa;
        padding: 20px;
        border-radius: 8px;
        max-width: 50%;

        h3 {
            color: #343a40;
            margin-bottom: 20px;
            font-size: 1.5em;
            text-align: center;
        }

        div {
            display: flex;
            flex-direction: column;
            gap: 10px;

            label {
                font-weight: bold;
                color: #495057;
                margin-bottom: 5px;
            }

            input[type="date"],
            input[type="text"] {
                padding: 10px;
                border: 1px solid #ced4da;
                border-radius: 5px;
                font-size: 1em;
                width: 100%;
                box-sizing: border-box;
                transition: border-color 0.2s;
            }

            button {
                background-color: #0693e3;
                color: #fff;
                border: 2px solid;
                border-color: #0693e3;
                border-radius: 5px;
                cursor: pointer;
                padding: 10px;
                font-size: 0.9em;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #fff;
                    color: #0693e3;
                }

                &:disabled {
                    background-color: #6c757d;
                    color: white;
                    cursor: not-allowed;
                }
            }
        }
    }

    .subcomponentsWrapper,
    .issWrapper,
    .activitiesWrapper,
    .actionsWrapper,
    .componenCorrectData {
        border: solid 2px $border-color-main;
        box-shadow: $box-shadow-main;
        background-color: #f1f3f5;
        padding: 20px;
        border-radius: 8px;

        h3 {
            color: #343a40;
            margin-bottom: 20px;
            font-size: 1.5em;
            text-align: center;
        }

        .btn {
            margin: 4px 4px;
            background-color: #0693e3;
            color: #fff;
            border: 2px solid;
            border-color: #0693e3;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            font-family: 'Roboto';
            font-weight: 400;
            font-size: 0.8rem;
        }

        .btn:hover {
            background-color: #fff;
            color: #0693e3;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
            table-layout: fixed;
            /* Dodanie table-layout: fixed dla stałego układu tabeli */
        }

        .tHead {
            background-color: #007bff;
            color: white;
            display: table-header-group;
        }

        .headTr th {
            padding: 10px;
            text-align: center;
            font-weight: bold;
            border: 2px solid #dee2e6;
            width: 12%;
            /* Ustawienie szerokości na 12% */
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .tBody {
            display: table-row-group;
            padding: 15px;
            text-align: center;

            tr {
                display: table-row;
                width: 100%;
            }

            .tbc,
            .na,
            .ok,
            .nok,
            .napr,
            .wphj {
                width: 12%;
                /* Ustawienie szerokości na 12% */
                box-sizing: border-box;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 5px;
                border-radius: 2px;
                color: #333;
                font-weight: bold;
                font-size: 12px;
                border: 2px solid #dee2e6;
            }

            .tbc {
                background-color: #ffeb3b;
                color: #333;
            }

            .na {
                background-color: #6c757d;
                color: white;
            }

            .ok {
                background-color: #218838;
            }

            .nok {
                background-color: #dc3545;
            }

            .napr,
            .wphj {
                background-color: #32f132;
            }

            .select {
                background-color: #0693e3;
            }

            .noAction,
            .action {
                background-color: #32f132;
            }

            .action {
                cursor: pointer;
            }
        }
    }



    .componenCorrectData {
        background-color: #f8f9fa;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        max-width: 50%;
        font-family: 'Arial', sans-serif;

        h3 {
            color: #343a40;
            margin-bottom: 20px;
            font-size: 1.5em;
            text-align: center;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        label {
            font-weight: bold;
            color: #495057;
            margin-bottom: 5px;
        }

        input[type="text"],
        input[type="date"],
        select {
            padding: 10px;
            border: 1px solid #ced4da;
            border-radius: 5px;
            font-size: 1em;
            width: 100%;
            box-sizing: border-box;
            transition: border-color 0.2s;
        }

        input[type="text"]:focus,
        input[type="date"]:focus,
        select:focus {
            border-color: #80bdff;
            outline: none;
        }

        button[type="submit"] {
            background-color: #0693e3;
            color: #fff;
            border: 2px solid;
            border-color: #0693e3;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            font: {
                family: 'Roboto';
                weight: 400;
                size: 1.2rem;
            }
        }

        button[type="submit"]:hover {
            background-color: #fff;
            color: #0693e3;
        }

        .error {
            color: #dc3545;
            background-color: #f8d7da;
            border: 1px solid #f5c6cb;
            padding: 10px;
            margin-bottom: 15px;
            border-radius: 5px;
            text-align: center;
        }
    }

    .actionsCorrectWrapper {
        display: flex;
        gap: 10px;
        justify-content: space-between;

        .actionsWrapper,
        .componenCorrectData {
            flex: 1;
            width: 50%;
            /* Ustawienie szerokości na 50% */
            border: solid 2px $border-color-main;
            box-shadow: $box-shadow-main;
            background-color: #f8f9fa;
            padding: 20px;
            border-radius: 8px;
            box-sizing: border-box;
            /* Dodano box-sizing dla lepszej kontroli paddingu */
        }
    }
}