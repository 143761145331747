@import 'lib/styles/mixins';
@import 'lib/styles/variables';

.wrapper {
    max-width: 99%;
    margin: 0 auto;
    padding: 1rem;
}

.selectComponentType {
    border: solid 2px $border-color-main;
    box-shadow: $box-shadow-main;
    border-radius: 8px;
    background-color: #f8f9fa;
    padding: 20px;
    display: flex;
    gap: 20px;
    color: #333;
    font-size: 0.9em;
    margin-bottom: 5px;

    label {
        font-size: 1rem;
        font-weight: 600;
        color: #333;
        margin-bottom: 5px;
    }

    select {
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        color: #333;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;

        &:focus {
            outline: none;
            border-color: #007bff;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }

        &:hover {
            border-color: #999;
        }

        &:disabled {
            background-color: #f5f5f5;
            border-color: #ddd;
            color: #aaa;
            cursor: not-allowed;
        }
    }

    option {
        padding: 0.5rem;
        font-size: 1rem;
    }
}


.selectComponentType {
    border: solid 2px $border-color-main;
    box-shadow: $box-shadow-main;
    border-radius: 8px;
    background-color: #f8f9fa;
    padding: 20px;
    display: flex;
    gap: 20px;
    color: #333;
    font-size: 0.9em;
    margin-bottom: 5px;

    label,
    select {
        padding: 5px;
    }
}

.wrapperPricingDashoboard {
    .buttonSection {
        margin-bottom: 5px;
    }
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;

    th,
    td {
        border: 1px solid #ddd;
        padding: 0.75rem;
        text-align: left;
    }

    th {
        background-color: #f5f5f5;
    }

    tr:hover {
        background-color: #fafafa;
    }
}

/* -- Modal -- */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; // kluczowe blokowanie tła
}

.modalContent {
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    min-width: 300px;
    max-width: 80%;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

/* -- Pola formularza w modalu -- */
.formField {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    label {
        font-weight: 600;
        margin-bottom: 0.25rem;
    }

    input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
    }
}

.wrapperSubcomponentPricingList {
    border: solid 2px $border-color-main;
    box-shadow: $box-shadow-main;
    border-radius: 8px;
    background-color: #f8f9fa;
    color: #333;
    font-size: 0.9em;
    margin-bottom: 5px;
}

.wrapperSubcomponentPricingList {

    border: solid 2px $border-color-main;
    box-shadow: $box-shadow-main;
    border-radius: 8px;
    padding: 20px;
    gap: 20px;
    color: #333;

    .expandableList {
        list-style: none;

        li {
            margin-bottom: 0.5rem;
            padding: 0.5rem;
            border: 1px solid #ddd;
            border-radius: 4px;
            background-color: #fafafa;

            /* Styl przycisku, który rozwija/zwija (używamy > bo dotyczy bezpośredniego buttona w li) */
            >button {
                background: none;
                border: none;
                cursor: pointer;
                font-weight: 600;
                display: inline-flex;
                align-items: center;
                gap: 0.5rem;
                color: #333;

                &:hover {
                    color: #000;
                }
            }

            // Kontener z rozwijaną zawartością
            .expandedContent {
                transition: max-height 0.3s ease;
                overflow: hidden;
                max-height: 0; // początek: zwinięte
                border: none; // brak ramki w stanie zwiniętym

                &.open {
                    max-height: 500px; // wystarczająca wartość dla zawartości
                    border: 1px solid #eee;
                    background-color: #fff;
                    padding: 5px;
                }
            }
        }
    }
}

.modalContent {
    position: relative;
    width: 80%;
    max-width: 900px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 80vh;
}

.scrollableContainer {
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 20px;
}

.orderSection {
    margin-bottom: 20px;
    /* Odstęp między zamówieniami */
    border: 1px solid #ddd;
    /* Obramowanie sekcji */
    border-radius: 8px;
    /* Zaokrąglone rogi */
    background-color: #f9f9f9;
    /* Jasne tło sekcji */
    padding: 15px;
    /* Wewnętrzne odstępy */
}

.orderDetails {
    margin-bottom: 10px;
    @include flex-space-btw;

    p {
        margin: 5px 0;
        font-size: 25px;
    }

    strong {
        color: #333;
        /* Kolor wyróżnienia */
    }
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff4d4f;
    /* Czerwony kolor */
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #d9363e;
        /* Ciemniejszy czerwony */
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.5);
        /* Fokus z lekkim czerwonawym obramowaniem */
    }
}

.componentTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;

    th, td {
        border: 1px solid #ddd;
        padding: 10px;
    }

    th:first-child, td:first-child {
        text-align: left; /* Pierwsza kolumna wyrównana do lewej */
    }

    th:nth-child(2), td:nth-child(2) {
        text-align: center; /* Druga kolumna wyrównana do środka */
    }

    th:last-child, td:last-child {
        text-align: right; /* Ostatnia kolumna wyrównana do prawej */
    }

    th {
        background-color: #f5f5f5;
        color: #333;
    }

    tr:nth-child(even) {
        background-color: #fafafa; /* Jasnoszary dla parzystych wierszy */
    }
}
