/** flexbox **/
@mixin flex {
	display: flex;
}
@mixin flex-column {
	@include flex;
	flex-direction: column;
}
@mixin flex-center {
	@include flex;
	justify-content: center;
	align-items: center;
}
@mixin flex-wrap {
	@include flex;
	flex-wrap: wrap;
}
@mixin flex-space-btw {
	@include flex;
	justify-content: space-between;
}
@mixin inline-flex-center {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
/** end flexbox **/

/** display block **/
@mixin in-line-block{
	display: inline-block;
}
@mixin in-line-block-center {
	display: inline-block;
	justify-content: center;
	align-items: center;
  }
/** end display block **/