@import '../../../lib/styles/mixins';
@import '../../../lib/styles/variables';

.wrapper {
   @include flex-center;
   width: 99%;
   border: 2px solid;
   border-color: #0693e3;
   border-radius: 5px;

   .form {
      @include flex-column;
      align-items: center; // Center items horizontally
      justify-content: center; // Center items vertically (optional)

      font: {
         family: 'Roboto';
         weight: 400;
      }

      h2 {
         text-align: center;
         font-weight: 700;
      }

      label,
      select,
      input {
         margin-bottom: 5px;
         width: 100%;
      }

      select {
         cursor: pointer;
      }

      .btn {
         margin: 5px;
         padding: 5px;
         text-decoration: none;
         background-color: #0693e3;
         color: #fff;
         border: 2px solid;
         border-color: #0693e3;
         border-radius: 5px;
         cursor: pointer;
         transition: background-color 0.3s;
         width: 30%;

         font: {
            family: 'Roboto';
            weight: 400;
            size: 0.6rem;
         }
      }

      .btn:hover {
         background-color: #fff;
         color: #0693e3;
      }
   }

   .orderAddNotification {
      width: 100%;

      h2 {
         text-align: center;
         font-weight: 700;
      }

      .btnSegment {
         @include flex-column;
         @include flex-center;

         .btn {
            margin: 5px;
            padding: 5px;
            text-decoration: none;
            background-color: #0693e3;
            color: #fff;
            border: 2px solid;
            border-color: #0693e3;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            font: {
               family: 'Roboto';
               weight: 400;
               size: 0.6rem;
            }
         }

         .aBtn {
            margin: 5px;
            padding: 5px;
            text-decoration: none;
            background-color: #0693e3;
            color: #fff;
            border: 2px solid;
            border-color: #0693e3;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
            text-align: center;

            font: {
               family: 'Roboto';
               weight: 400;
               size: 0.6rem;
            }
         }

         .btn:hover,
         .aBtn:hover {
            background-color: #fff;
            color: #0693e3;
         }
      }
   }
}