@import 'lib/styles/mixins';
@import 'lib/styles/variables';

.modalConfirmationWindow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); // Półprzezroczyste tło
    z-index: 10000; // Wysoki z-index, aby modal zawsze był nad innymi elementami
    display: flex;
    align-items: center;
    justify-content: center;

    .modalContent {
        background: white;
        padding: 20px;
        border-radius: 8px;
        width: 400px; // Ustaw szerokość okna
        max-width: 90%; // Zmieści się na mniejszych ekranach
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); // Delikatny cień
        z-index: 1100; // Modal ma wyższy z-index niż tło
        text-align: center;

        button {
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
            margin: 10px;
        }

        .confirmButton {
            background-color: #4CAF50; // Zielony dla potwierdzenia
            color: white;

            &:hover {
                background-color: #45a049; // Jaśniejszy odcień na hover
            }
        }

        .cancelButton {
            background-color: #f44336; // Czerwony dla anulowania
            color: white;

            &:hover {
                background-color: #e53935; // Jaśniejszy odcień na hover
            }
        }

    }
}