@import '../../lib/styles/mixins';
@import '../../lib/styles/variables';

.wrapper {
    text-align: center;

    font: {
        family: 'Roboto';
    }

    .ulList {
        @include flex-column;
        @include flex-center;
        list-style: none;
        padding: 0;

        .btn {
            margin: 2px 0;
            width: 180px;
            height: 50px;
            text-decoration: none;
            background-color: #0693e3;
            color: #fff;
            border: 2px solid;
            border-color: #0693e3;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            font: {
                weight: 400;
                size: 0.8rem;
            }
        }

        .btn:hover {
            background-color: #fff;
            color: #0693e3;
        }
    }

    .table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
        table-layout: fixed;

        .tHead {
            background-color: #0693e3;
            color: white;
            font-weight: bold;

            th {
                font-size: 14px;
                border: 2px solid #ddd;
                padding: 2px;

                .filterIcon {
                    cursor: pointer;
                    margin-left: 8px;
                    color: #ddd;
                    transition: color 0.3s;
                }

                .activeFilter {
                    color: green;
                }

                .inactiveFilter {
                    color: gray;
                }
            }
        }

        .tBody {
            .bodyTr {
                font-size: 12px;
                cursor: pointer;
                transition: background-color 0.3s;

                &:nth-child(even) {
                    background-color: #f2f2f2;
                }

                td {
                    border: 1px solid #ddd;
                    text-align: center;
                }
            }

            .bodyTr:hover {
                background-color: #2980b9;
                color: white;
            }

            .bodyTr:hover {
                background-color: #3498db;
            }
        }
    }

    .filterContainer {
        @include flex-center;
        margin-bottom: 10px;
        justify-content: center;

        .filterLabel {
            margin-right: 8px;
            font-weight: bold;
        }

        .filterInput {
            padding: 4px;
            border: 2px solid #0693e3;
            border-radius: 5px;
            font-size: 0.8rem;
            width: 200px;
        }

        .filterTypeSelect {
            margin-left: 8px;
            padding: 4px;
            border: 2px solid #0693e3;
            border-radius: 5px;
            font-size: 0.8rem;
            background-color: #fff;
            color: #333;
            cursor: pointer;
            transition: border-color 0.3s;

            &:hover {
                border-color: #2980b9;
            }
        }

        .closeFilterButton {
            background: none;
            border: none;
            color: red;
            font-size: 16px;
            cursor: pointer;
            margin-left: 8px;
            padding: 2px 6px;
            border-radius: 50%;
            transition: background-color 0.3s;

            &:hover {
                background-color: #f2f2f2;
            }
        }
    }

    .applyFiltersContainer {
        margin-bottom: 20px;
        text-align: center;

        .applyFiltersButton {
            background-color: #0693e3;
            color: #fff;
            border: 2px solid #0693e3;
            border-radius: 5px;
            padding: 10px 20px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #fff;
                color: #0693e3;
            }
        }
    }

    .pagination,
    .pageInput {
        .btn {
            margin: 5px;
            padding: 5px;
            text-decoration: none;
            background-color: #0693e3;
            color: #fff;
            border: 2px solid;
            border-color: #0693e3;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            font: {
                family: 'Roboto';
                weight: 400;
                size: 0.6rem;
            }
        }

        .btn:hover {
            background-color: #fff;
            color: #0693e3;
        }

        .input {
            padding: 4px;
            border-radius: 5px;
            border: 2px solid;
            border-color: #0693e3;
        }
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .wrapper {
        .table {
            .tHead {
                .headTr {
                    th {
                        font-size: 7px;

                        .filterInput {
                            font-size: 7px;
                        }

                        .filterTypeSelect {
                            font-size: 7px;
                        }
                    }
                }
            }

            .tBody {
                .bodyTr {
                    td {
                        font-size: 6px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .wrapper {
        .table {
            .tHead {
                .headTr {
                    th {
                        font-size: 10px;

                        .filterInput {
                            font-size: 10px;
                        }

                        .filterTypeSelect {
                            font-size: 10px;
                        }
                    }
                }
            }

            .tBody {
                .bodyTr {
                    td {
                        font-size: 8px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1440px) {
    .wrapper {
        .table {
            .tHead {
                .headTr {
                    th {
                        font-size: 14px;

                        .filterInput {
                            font-size: 14px;
                        }

                        .filterTypeSelect {
                            font-size: 14px;
                        }
                    }
                }
            }

            .tBody {
                .bodyTr {
                    td {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
