@import '../../../lib/styles/mixins';
@import '../../../lib/styles/variables';

.btnWrapper {
    button {
        margin: 2px 0;
        width: 120px;
        height: 40px;
        text-decoration: none;
        background-color: #28a745;
        color: #fff;
        border: 2px solid;
        border-color: #28a745;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        font: {
            weight: 700;
            size: 0.8rem;
        }
    }

    .btn:hover {
        background-color: #fff;
        color: #28a745;
    }
}