@import '../../../lib/styles/mixins';
@import '../../../lib/styles/variables';

.wrapper {
    @include flex-space-btw;
    margin-bottom: 5px;

    a {
        text-decoration: none;
        color: inherit;

        .hjOutsoursing {
            width: 122px;
            height: 32px;
        }
    }

    .btnSegment {

        .aBtn,
        .btn,
        .dropdownContent a {
            background-color: #0693e3;
            color: #fff;
            border: 2px solid;
            border-color: #0693e3;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
            padding: 5px;
            margin-right: 2px;

            font: {
                family: 'Roboto';
                size: 16px;
            }
        }

        .aBtn:hover,
        .btn:hover,
        .dropdownContent a:hover {
            background-color: #fff;
            color: #0693e3;
        }

        .dropdown {
            position: relative;
            display: inline-block;

            .dropdownContent {
                display: none;
                position: absolute;
                background-color: #f9f9f9;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
                    0px 12px 24px 0px rgba(0, 0, 0, 0.2),
                    0px 16px 32px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                border-radius: 5px;
                padding: 10px;
                right: 0;
                /* Przesunięcie od prawej strony */
                transform: translateX(-2px);
                /* Dostosuj wartość według potrzeb */

                a {
                    display: block;
                    margin-bottom: 5px;
                }

                a:last-child {
                    margin-bottom: 0;
                }
            }

            .visible {
                display: block;
            }

            &:hover .dropbtn {
                background-color: #fff;
                color: #0693e3;
            }
        }
    }
}

@media (max-width: 319px) {
    .wrapper {
        .btnSegment {

            .aBtn,
            .btn,
            .dropdownContent a {
                font-size: 7px;
                padding: 3px;
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 479px) {
    .wrapper {
        .btnSegment {

            .aBtn,
            .btn,
            .dropdownContent a {
                font-size: 9px;
                padding: 4px;
            }
        }
    }
}

@media (min-width: 480px) and (max-width: 766px) {
    .wrapper {
        .btnSegment {

            .aBtn,
            .btn,
            .dropdownContent a {
                font-size: 9px;
                padding: 5px;
            }
        }
    }
}