@import '../../../lib/styles/mixins';
@import '../../../lib/styles/variables';

.wrapper {
    position: fixed;
    bottom: 0; 
    right: 0;

    .version {
        font-size: 0.5em;
    }
}